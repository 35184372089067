import React from 'react'
import styled from 'styled-components';
import {graphql, useStaticQuery} from 'gatsby';
import Fade from 'react-reveal/Fade';
import Layout from '../components/Layout';
import Quote from '../components/Quote';
import showreel from '../videos/showreel.mp4';
import fallbackImg from '../images/1stmainhologram.jpg';
import SEO from '../components/seo'
import Link from "../components/Link";

const WorkPage = () => {

    const data = useStaticQuery(graphql`
query MyQuery3 {
    allStrapiWorkImages(sort: {fields: published_at, order: DESC}) {
      edges {
        node {
          image {
            publicURL
          }
          id
          imageText
        }
      }
    }
    allStrapiStartups(sort: {fields: id}) {
        pageInfo {
            itemCount
        }
        edges {
          node {
            content
            title
            study
            id
            order
            image{
                publicURL
            }
            link
          }
        }
      }
    allStrapiClient(sort: {fields: id}) {
      pageInfo {
        itemCount
      }
      edges {
        node {
          content
          study
          title
          id
          order
          year
          link
        }
      }
    }
    allStrapiCarouselTexts {
        edges {
          node {
            content
            title
            position
          }
        }
    }
  }      
`)
    const ImageSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2.1,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: true,
        // centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                }
            },
        ]
    };
    const renderSlides = () =>
        data.allStrapiCarouselTexts.edges.map( (doc,key)  => (
            <div className="work__slider" key={key}>
                <h1>{doc.node.content}</h1>
                <div className="carousel__details pt-4">
                    <h4>{doc.node.title}</h4>
                    <h4>{doc.node.position}</h4>
                </div>
            </div>
        ));
    const renderImages = () =>
        data.allStrapiWorkImages.edges.map( (doc,key) => (
            <div className="img__slider" key={key}>
                <img src={doc.node.image.publicURL} alt=""/>
                <h4>{doc.node.imageText}</h4>
            </div>
        ));
    return (
        <StyledWrapper>
            <Layout>
                <SEO title="Work" description="We work with respected businesses from around the world to solve their toughest digital
                        product challenges. Clients include WeWork, upReach, The Marg Foundation, 10club, Rubamin and
                        more."
                    path="/work"/>
                <div className="work">
                    <div className="work__main">
                        <video poster={fallbackImg} autoPlay="autoplay" loop="loop" muted="muted" playsInline className="showreel w-100">
                            <source src={showreel} type="video/mp4" />
                            <img src={fallbackImg} alt="fallback image for a showreel of 1st main work" />
                        </video>
                        {/*<div className="work__title">*/}
                        {/*    <h2>Case Studies</h2>*/}
                        {/*</div>*/}
                        {/*<Slider {...ImageSettings}>*/}
                        {/*    {renderImages()}*/}
                        {/*</Slider>*/}
                    </div>
                    <div className="work__client container mb-5">
                        <h2>Select Clients</h2>
                        <div className="row">
                            {data.allStrapiClient.edges.map( (doc,key) => (
                                <Fade key={key}>
                                    <div className="select-client col-lg-4 col-sm-6 col-12 my-4 d-flex flex-column">
                                        <p className="project-year">{doc.node.year}</p>
                                        <h4>{doc.node.title}</h4>
                                        <p className="project-desc">{doc.node.content}</p>
                                        <div className="mt-auto mb-3">
                                            <Link to={doc.node.link !== null ? doc.node.link : ''}>{doc.node.study}</Link>
                                        </div>
                                        {/*{data.allStrapiClient.pageInfo.itemCount > doc.node.order ?*/}
                                        {/*    <hr style={{width: "100%"}} className="d-sm-none d-block"></hr> : <></>}*/}
                                    </div>
                                    {/*{doc.node.order % 3 === 0 ?*/}
                                    {/*    <hr style={{width: "95%"}} className="d-sm-block d-none"></hr> : <></>}*/}
                                </Fade>
                            ))}
                        </div>
                    </div>
                    <div className="work__startup container pt-5">
                        <h2 className="d-none">Startups we partnered with to launch</h2>
                        <div className="row d-none">
                            {data.allStrapiStartups.edges.map( (doc, key) => (
                                <Fade key={key}>
                                    <div className="startup col-lg-4 col-sm-6 col-12 mt-3">
                                        <img className="startup__images mb-2" src={doc.node.image.publicURL}
                                             alt=""/>
                                        {/* <h3>{doc.node.title}</h3> */}
                                        <p className="project-desc">{doc.node.content}</p>
                                        <Link to={doc.node.link !== null ? doc.node.link : ''}>{doc.node.study}</Link>
                                        {data.allStrapiStartups.pageInfo.itemCount > doc.node.order ?
                                            <hr style={{width: "100%"}} className="d-sm-none d-block"></hr> : <></>}
                                    </div>
                                    {/* {doc.node.order % 3 === 0 ? <hr style={{width:"95%"}} className="d-sm-block d-none"></hr> : <></>} */}
                                </Fade>
                            ))}
                        </div>
                        <Quote/>
                    </div>
                </div>
            </Layout>
        </StyledWrapper>
    )
}

export default WorkPage;

const StyledWrapper = styled.div`

  .slick-slide {
    padding: 0 10px;
  }
  
  .project-year {
    margin-bottom: 0;
    font-size: 12px;
  }

  .project-desc {
    font-size: 14px;
    line-height: 22px;
  }

  .work {
    height: 100%;
    transition: 750ms;
  }

  .work__main {
    padding: 100px 100px 50px 100px;
  }

  .work__title > h2 {
    margin: 100px 0 0;
    height: 50px;
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
  }

  .work__client > h2, .work__startup > h2 {
    margin-bottom: 10px;
    font-weight: 600;
  }

  .img__slider > img {
    padding: 10px;
    width: 100%;
    object-fit: cover;
    height: 450px;
  }

  .img__slider > h4 {
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    margin-top: 10px;
  }

  .img__slider {
    padding: 65px 0px;
  }


  .startup__images {
    height: 40px;
  }

  .select-client:after {
    content: ""; /* This is necessary for the pseudo element to work. */
    display: block; /* This will put the pseudo element on its own line. */
    margin: 0 auto; /* This will center the border. */
    width: 100%; /* Change this to whatever width you want. */
    padding-top: 20px; /* This creates some space between the element and the border. */
    border-bottom: 1px solid #C2C9D1; /* This creates the border. Replace black with whatever color you want.*/
  }

  .select-client::after:last-child {
    border: none !important;
  }

  @media (max-width: 992px) {

    
    .work {
      border-left: none;
    }

    .work__main {
      padding: 50px 20px;
    }

    .img__slider > img {
      object-fit: cover;
      height: 250px;
      width: 100%;
      padding: 0px;
    }



    .startup {
      padding-bottom: 40px;
    }

    .main {
      border-left: none;
    }

    .work__title > h2 {
      font-size: 24px;
      margin-bottom: 20px;
      padding: 80px 0px 20px;
    }

    .showreel {
      margin-top: 80px;
    }

    .img__slider {
      padding: 30px 0px;
    }

  }

  @media (min-width: 576px) {


    .select-client:after {
      width: 90%; /* Change this to whatever width you want. */
    }
    
    .select-client, .startup {
      border-right: 1px solid #C2C9D1;
    }

    .select-client:nth-child(2n+0), .startup:nth-child(2n+0) {
      border-right: none;
    }

    .startup {
      border-right: 1px solid #C2C9D1;
    }

    .select-client:last-child {
      border: none !important;
    }

    .select-client:last-child::after {
      content: "";
      border: none !important;
    }

  }

  @media (min-width: 992px) {

    video {
      padding: 0 75px;
    }

    .work__content {
      padding-left: 103px;
      padding-right: 20px;
    }

    .select-client:nth-child(2n+0), .startup:nth-child(2n+0) {
      border-right: 1px solid #C2C9D1;
    }

    .select-client:nth-child(3n+0), .startup:nth-child(3n+0) {
      border-right: none;
    }


  }
`